@import "../../../../_var.scss";

.saveBtn {
  &.ui.button.btnCustom {
    min-width: 172px;
    height: 50px;
    border-radius: 5px;
    margin-right: 33px;
  }
}

.addedTeamBtn {
  &.ui.button.btnCustom {
    margin-bottom: 24px;
    height: 32px;
    border-radius: 5px;
    display: flex;
    background: $white;
    color: $grey;
    font-weight: bold;
    font-size: 12px;

    &:active {
      background: $borderColor2;
      color: $darkText2;

      svg {
        path {
          fill: $darkText2;
        }
      }
    }

    &:hover {
      color: $darkText2;

      svg {
        path {
          fill: $darkText2;
        }
      }
    }

    svg {
      path {
        fill: $grey;


      }
    }
  }
}

.cancelBtn {
  &.ui.button.btnCustom {
    height: 50px;
    border-radius: 5px;
    margin-right: 33px;
    background: $white;
    border: 1px solid $borderColor2;
    color: $darkText2;

    &:hover {
      border-color: $darkButton;
    }
  }
}

.addNew_Btn {
  height: 50px;
  min-width: 172px;
  border-radius: 5px;
}

.gallerySect {
  display: flex;
  gap: 20px;
  width: 651px;
  // border-bottom: 1px dashed $fashedBorder;
  border-top: 1px dashed $fashedBorder;
  padding: 24px 0;
  // margin-bottom: 20px;

  &__rowBoard {
    width: 100%;

    display: flex;
    flex-direction: row;
  }

  &__imgInput {
    width: 180px;
    display: flex;
    flex-direction: column;
  }

  &__imgDescript {
    display: flex;
    width: 457px;
    margin-left: 32px;
    flex-direction: column;
  }

  &__imgDetails {
    font-weight: normal;
    font-size: 12px;
    color: $grey;
    // margin-top: 35px;
  }
}

.addQues_Btn {
  cursor: pointer;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: $mainText;
  font-size: 16px;
  font-weight: 600;

  img {
    margin-right: 6px;
  }
}

.people {
  display: flex;
  flex-direction: row;
  padding: 24px 0;
  border-top: 1px dashed $fashedBorder;
  border-bottom: 1px dashed $fashedBorder;
  width: 650px;

  &__imgSect {
    display: flex;
    flex-direction: column;
    width: 170px;
  }

  &__detailsSec {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    width: 352px;
  }

  &__imgSize {
    font-weight: normal;
    font-size: 12px;
    color: $grey;
  }

  &__buttonSect {
    width: 650px;
  }

}

.right {
  .top-controll {
    max-width: 540px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 991px) {
      max-width: 100%;
    }

    .publicprev {
      padding: 6px 10px;
      font-size: 12px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      // padding-left: 40px;
      // background-image: url(../../../../Assets/image/svgimages/prev-icon.svg);
      // background-position: 15px center;
      // background-size: auto;
      // background-repeat: no-repeat;
      border-radius: 5px;
      transition: 0.3s all ease-in-out;
      color: $darkText2;
      border: 1px solid $borderColor2;

      svg {
        margin-right: 10px;
        vertical-align: middle;
        // margin-left: 5px;
      }

      &:hover {
        border-color: $borderColor;
      }

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    >a {
      margin-bottom: 24px;
    }
  }
}

.new-table {
  overflow-x: auto;

  .tableEdti {
    width: 100%;
    overflow-x: scroll;
  }

  .TableEditBtn:last-child {
    min-width: 0px;
    padding: 0;
  }

  .TableTdSpace:last-child {
    width: 0 !important;
  }

  .pro {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 5px 15px 5px;
  }

  .edt,
  .dlt {
    margin: 5px 15px 5px;
  }

  .sociali {
    display: inline-block;
    margin: 5px 15px 5px;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: $navTextHoverColor;
      }
    }
  }

  .editand_DltIcon {
    display: flex;
    justify-content: space-between;

    .EditBtnsIcon,
    .DeltBtnsIcon {
      background-color: #fbfbfb;
      border: 1px solid #e7e7e7;
      margin-right: 10px;
      padding-bottom: 3px;
      cursor: pointer;
      border-radius: 5px;
      min-width: 30px;

      svg {
        vertical-align: middle;
      }

      &:hover {
        background: #932040;
        border: 1px solid #932040;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .DeltBtnsIcon {
      margin: 0 !important;
    }
  }
}



.TeamModalNew {
  .uploadField {
    .profilePic {
      display: flex;
      align-items: center;
      width: 180px;
      height: 120px;

      .profilePic-inner {
        position: relative;
        background-color: $lightgrey;
        border: 1px solid $borderColor2;
        border-radius: 15px;

        .input__input {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          border-radius: 15px;
          z-index: 2;
          cursor: pointer;

        }

        .file-input__prof {
          background-color: transparent;
          position: relative;
          margin: 0;
          border: 0;
          cursor: pointer;
          padding: 0;
          border-radius: 15px;
          z-index: 1;
          width: 540px;

          img[src=""] {
            display: none;
          }

          img {
            height: 100% !important;
            object-fit: fill;
            border-radius: 15px;
          }

          &::before {
            content: "";
            height: 64px;
            width: 64px;
            border-radius: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            background-color: $white;
            background-image: url(../../../../Assets/image/upload.svg);
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: center;
            border: 1px solid $borderlight;
            z-index: 1;
          }
        }

        &.big {
          .file-input__prof {
            @media (min-width: 1200px) {
              height: 350px;
              width: 540px;
            }
          }
        }

        &.large {
          .file-input__prof {
            @media (min-width: 1280px) {
              height: 350px;
              width: 540px;
            }
          }
        }
      }

      .file-input__importent {
        padding-left: 30px;

        @media (max-width: 575px) {
          padding-left: 0;
        }

        p,
        strong {
          font-size: 14px;
          color: $grey;
        }
      }

      @media (max-width: 575px) {
        flex-wrap: wrap;

        .file-input__importent {
          flex: 0 0 100%;
          padding-top: 30px;
        }
      }
    }
  }

  .actions {
    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      padding-bottom: 15px;

      button {
        min-width: inherit;
        margin: 5px 5px 15px !important;
      }
    }
  }
}

.continBtn {
  display: flex;
  justify-content: space-between;
  // align-items: center;
}

.heading {
  font-family: $baseFont;
  font-weight: normal;
  font-size: 20px;
  color: #21040C;
  padding-bottom: 8px;
}

.editRichText {
  margin-top: 24px;
}

.sectionInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 540px;
  width: 100%;

  &.mt-24 {
    margin-top: 24px;
  }

  &.mt-1 {
    margin-top: 8px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }

  &.vertical-top {
    align-items: end;
  }

  .sizeImg {
    font-weight: normal;
    font-size: 14px;
    color: $grey;
  }

  .detailsRul {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .InfoToggle {
    width: 46px;
    height: 40px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    position: relative;
    left: 115px;
    min-width: 46px;

    &.bottles {
      left: 0;
    }
  }
}

.AdduserInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 540px;
  width: 100%;
  position: relative;

  @media (max-width: 991px) {
    max-width: 100%;
  }

  .InfoToggle {
    width: 46px;
    height: 40px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    position: absolute;
    right: -65px;
    bottom: 32px;
  }

  .InfoIconTogg {
    top: 66px;

    @media (max-width: 991px) {
      top: 27px;
    }
  }
}

.popup.transition.visible.trigerEdit {
  background: #932040;
  box-shadow: 0px 7px 15px #00000029;
  color: #fff;
  min-width: 370px;
  // top: 64px !important;

  &::before {
    background: #932040;
    // top: 7px;
  }
}

.checkbox_wrapp {
  margin-bottom: 15px;

  p {
    display: block;
    font-size: 13px;
    font-weight: 700;
    color: #21040c;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .ant-checkbox-wrapper {
    display: flex;
  }

  .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $navTextHoverColor;
    border-color: $navTextHoverColor;
  }

  .ant-checkbox-checked:after {
    border-color: $navTextHoverColor;
    animation: none;
    animation-fill-mode: none;
  }

  .ant-checkbox-input:focus+.ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $navTextHoverColor !important;
  }
}

.AdditionIfo {
  background: #fff !important;
  text-align: center;
  padding: 10px 0 0 !important;

  .imgselector {
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
  }

  .infoContaent {
    background: #932040;
    padding: 10px;
  }
}

.infoDate {
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
}

.TopicAdd {
  margin: 0 0 50px;
  border: none;
  background: transparent;
  height: 40px;
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  color: $grey;
  font-weight: 600;
}

.block {
  &__title {
    margin-bottom: 18px;
    color: $darkText2;
    font-weight: 500;
    font-family: $baseFont2;
    font-size: 18px;
  }

  &__quantity {
    margin-bottom: 28px;
    color: $grey;
    font-weight: normal;
    font-family: $baseFont;
    font-size: 14px;
  }

  &__addCalleryBtn {
    background: $white;
    margin: 20px 0;
  }
}

.descriptionPoint {
  position: relative;

  .AdditontRanges {
    top: -15px !important;
  }

  .InfoDetails {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 60%;
    max-width: 398px;
    min-height: 170px;
    width: 100%;
    background: #fffdd0 0% 0% no-repeat padding-box;
    border: 1px solid #eee75b;
    padding: 28px 24px;

    @media (max-width: 1680px) {
      position: relative;
      z-index: 1;
      top: 0;
      left: 0;
      max-width: 100%;
      margin-left: 0px;
      margin-bottom: 20px;
    }

    .inputField__label {
      font-size: 16px;
      color: #23262c;
    }

    p {
      font-size: 14px;
    }
  }
}

.custom-error-text {
  color: red;
  font-size: 13px;
  margin-top: -28px;
}

.imageActions {
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 11;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fbfbfb;
    border: 1px solid #e7e7e7;
    padding: 3px;
    cursor: pointer;
    border-radius: 5px;
    min-width: 30px;
    margin-left: 5px;

    &:hover {
      background: #932040;
      border: 1px solid #932040;

      svg {
        path {
          fill: #fff;
          vertical-align: middle;
        }
      }
    }
  }

}

.starIsRequired {
  color: #932040;
  left: -0.5em
}