@import "../../../../_var.scss";

.winemaker_wrapper {
  //white-space: pre-wrap;
  background-color: #fafcff;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  &__iframe{
    flex-grow: 1;
  }

  .team__outer.winner__team {
    .ant-row {
      margin-top: 30px;
      display: flex;
      justify-content: flex-start;
    }
  }

  .back-to-prev {
    background-color: $yellow2;
    padding: 15px 0;

    .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1110px;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;

      button {
        margin: 0;

        &.previous {
          svg {
            margin-right: 5px;
            path {
              fill: $grey;
            }
          }
        }

        @media (max-width: 767px) {
          min-width: inherit;
          width: auto;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  .public-DraftEditor-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }
}
